























































































































import Vue from 'vue';

interface Data {
    job: {
        benefits: number[],
    },
    benefitFlags: number
}

export default Vue.extend({
    name: 'BenefitsSelectorView',
    props: {
        preselected: Number,
        invalid: Boolean
    },
    data(): Data {
        return {
            job: {
                benefits: []
            },
            benefitFlags: 0
        }
    },
    methods: {
        uploadBenefits(): void {
            // reset flags
            this.benefitFlags = 0;
            this.job.benefits.forEach(this.bitshift);

            this.$emit('postBenefits', this.benefitFlags);
        },
        bitshift(v: number){
            this.benefitFlags |=  (1 << v);
        }
    },
    mounted() {
        if(this.preselected != undefined){
            // decode preselected to checkbox values
            for(let i = 0; i < 22; i++){
                if((this.preselected & 1 << i) != 0){
                    this.job.benefits.push(i);
                }
            }
        }
    }
});
