















import Vue from 'vue'
import QualQuestion from '@/models/QualQuestion';

export default Vue.extend({
    name: 'QuestionCard',
    props: {
        question: { type: Object as () => QualQuestion, required: true }
    }
})
