import { UUID, NullableString } from './Types';
import Company from './Company';
import JobLocation from './JobLocation';
import { ApplicantSource } from './JobApplication';

export default interface JobDetails {
    id: UUID | null,
    companyId: UUID | null,
    company: Company | null,
    title: string,
    functionalRole: string,
    location: JobLocation | null,
    radiusInMiles: string,
    remote: boolean,
    relocation: boolean,
    reloPackage: boolean,
    details: NullableString,
    aboutUs: NullableString,
    whyUs: NullableString,
    requiredSkills: string[],
    optionalSkills: string[],
    educationalReqs: NullableString,
    orExperience: boolean,
    compensation: {
        type: string,
        min: number,
        max: number
    },
    commissions: boolean,
    bonuses: boolean,
    benefits: number,
    status: number,
    campaign: string,
    campaignId: string,
    currentSource: ApplicantSource,
    notify: boolean,
    employmentType: string
}

export const DefaultJobDetails: JobDetails = {
    id: null,
    companyId: null,
    company: null,
    title: '',
    functionalRole: '',
    location: null,
    radiusInMiles: '',
    remote: false,
    relocation: false,
    reloPackage: false,
    details: null,
    aboutUs: null,
    whyUs: null,
    requiredSkills: [],
    optionalSkills: [],
    educationalReqs: null,
    orExperience: false,
    compensation: {
        type: '',
        min: 0,
        max: 0
    },
    commissions: false,
    bonuses: false,
    benefits: 0,
    status: 0,
    campaign: '',
    campaignId: '',
    currentSource: 0,
    notify: false,
    employmentType: ''
}