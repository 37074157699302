




















































import Vue from 'vue';
import QualQuestion, { Answer } from '@/models/QualQuestion';

export default Vue.extend({
    name: 'AnswerConfigCard',        
    props: {
        question: { type: Object as () => QualQuestion, required: true }
    },
    data() {
        return {
            parameterBoxWidth: 50
        }
    },
    methods: {
        toggleWeight() {
            if(this.question.isMultichoice) { // MC
                this.question.answers.forEach((a, i) => {
                    if(a.isDealbreaker) {
                        a.weight = -9999;
                    }
                });
            } else { // T/F
                let no = this.question.answers.slice(-1)[0];
                if(no.isDealbreaker) {
                    this.question.answers[0].weight = 9;
                    no.weight = -9999;
                }
            }
        },
        close() {
            this.question.isAdded = false;
        },
        uniqueLabel(ans: string): string {
            return `${this.question.questionId!.substring(0,5)}_${ans}`;
        },
        // addMoreAnswer() {
        //     if(this.question.isMultichoice) {
        //         let newAnswer:Answer = {
        //             label: 'New Choice',
        //             weight: 5,
        //             isDealbreaker: false
        //         };
        //         this.question.answers.push(newAnswer);
        //     }
        // },
        resizeWidth() {
            if(this.question.isParameterized && this.question.parameter != null) {
                let boxWidth:number = this.question.parameter.length * 9.5 + 12;

                if(boxWidth > 50) this.parameterBoxWidth = boxWidth;
                else this.parameterBoxWidth = 50;
            }
            
        }
    },
    computed: {
        parameterizedText(): string[] {
            return this.question.isParameterized ? this.question.question.split('_____') : [ this.question.question ];
        }
    },
    async mounted() {
        try {
            let jobId = this.$route.params.id;

            if(this.question.isRepeatable) {
                this.$emit("addedRepeater"); // currently constrained via pk
            }
        } catch (error) {
            // eslint-disable-next-line
            console.error(error);
        }
    }
});
