




















































































































































































































































































































import Vue from 'vue';
import api from '@/api';
import { UUID } from '@/models/Types';
import UserSession from '@/models/UserSession';
import { SystemPermissions } from '@/models/Permissions';
import CompanyAbstract from '@/models/CompanyAbstract';
import JobDetails, { DefaultJobDetails } from '@/models/JobDetails';
import { DefaultJobLocation } from '@/models/JobLocation';
// components
import JobLocationView from '@/components/job/JobLocationView.vue';
import BenefitsSelectorView from '@/components/job/BenefitsSelectorView.vue';
import MaterialInput from '@/components/MaterialInput.vue';
import MaterialSelect from '@/components/MaterialSelect.vue';
import MaterialTextarea from '@/components/MaterialTextarea.vue';
import MaterialMarkdownEditor from '@/components/MaterialMarkdownEditor.vue';
// validation
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, regex } from 'vee-validate/dist/rules';
import BreadCrumb from '@/components/BreadCrumb.vue';

extend('required', {
  ...required,
  message: '{_field_} is required',
});

extend('regex', {
  ...regex,
  message: 'Please use a format like $10.00, 10.00, or 10',
});

interface SessionVM extends UserSession {
  payload: CompanyAbstract;
}

interface DropdownItem {
  id: string;
  name: string;
}

interface Data {
  company: CompanyAbstract | null;
  job: JobDetails | null;
  musthaves: string;
  nicehaves: string;
  eduLevels: DropdownItem[];
  rateTypes: DropdownItem[];
  jobFunctions: DropdownItem[];
  empTypes: DropdownItem[];
  minRate: string;
  maxRate: string;
  // campaignId: string;
}

export default Vue.extend({
  name: "EditJob",
  components: {
    JobLocationView,
    BenefitsSelectorView,
    MaterialInput,
    MaterialSelect,
    MaterialTextarea,
    MaterialMarkdownEditor,
    ValidationProvider,
    ValidationObserver,
    BreadCrumb,
  },
  data(): Data {
    return {
      company: null,
      job: null,
      musthaves: '',
      nicehaves: '',
      eduLevels: [],
      rateTypes: [],
      jobFunctions: [],
      empTypes: [],
      minRate: '',
      maxRate: ''
      // campaignId: ''
    };
  },
  computed: {
    viewWBAdminControls(): boolean {
      return (
        (this.$store.getters.sysPrivileges & SystemPermissions.Administrator) ==
        SystemPermissions.Administrator
      );
    },
  },
  methods: {
    async uploadJob(v: number) {
      this.job!.benefits = v;

      this.job!.compensation.min = Number.parseFloat(
        this.minRate.match(/[0-9\.]+/g)!.join('')
      );
      this.job!.compensation.max = Number.parseFloat(
        this.maxRate.match(/[0-9\.]+/g)!.join('')
      );

      if (this.musthaves.length > 0) {
        this.job!.requiredSkills = this.musthaves.trim().split('\n');
      }
      if (this.nicehaves.length > 0) {
        this.job!.optionalSkills = this.nicehaves.trim().split('\n');
      }

      if (
        this.job!.location != null &&
        this.job!.location.companyId!.length != 36
      ) {
        // a non-org location was specified
        this.job!.location.companyId = '00000000-0000-0000-0000-000000000000';
      }

      // post to 'create' endpoint
      try {
        const rslt = await this.$http.post(`${api}/jobs/create`, this.job, {
          headers: { Accept: 'application/json' },
        });
        let jid = rslt.data as string;
        window.location.assign(`/#/jobs/qualifiers/${jid}`);
      } catch (error) {
        // eslint-disable-next-line
        console.error(error);
      }
    },
    // async refreshCampaign() {
    //   // post to 'create' endpoint
    //   try {
    //     const rslt = await this.$http.get(`${api}/jobs/${this.job!.id}/campaign-refresh`);
    //     this.job!.campaignId = rslt.data;
    //   } catch (error) {
    //     // eslint-disable-next-line
    //     console.error(error);
    //   }
    // }
  },
  async mounted() {
    // pull company id
    this.$store.commit('setIsLoading', true);
    const pid: UUID = this.$store.getters.currentProfile;
    if (pid == null) {
      // pull user's associated profile and redirect to home dashboard
      this.$router.push({ path: `/boards/${this.$store.getters.homeProfile}` });
      return;
    }

    // get company
    try {
      const rslt = await this.$http.get(`${api}/profiles/${pid}`);
      const rspn: SessionVM = rslt.data;
      this.company = rspn.payload;

    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    }

    const jobId: UUID = this.$route.params.id; // only defined if Editing existing job
    if (jobId != null) {
      // edit mode
      try {
        const rslt = await this.$http.get(`${api}/jobs/${jobId}`);
        this.job = rslt.data;

        if (this.job!.requiredSkills.length > 0) {
          this.musthaves = this.job!.requiredSkills.join('\n');
        }
        if (this.job!.optionalSkills.length > 0) {
          this.nicehaves = this.job!.optionalSkills.join('\n');
        }

        this.minRate = new Intl.NumberFormat(`en-US`, {
          currency: `USD`,
          style: 'currency',
        }).format(this.job!.compensation.min);
        this.maxRate = new Intl.NumberFormat(`en-US`, {
          currency: `USD`,
          style: 'currency',
        }).format(this.job!.compensation.max);
      } catch (error) {
        // eslint-disable-next-line
        console.error(error);
      }
    } else {
      // create mode
      // assign defaults from company
      this.job = {
        ...DefaultJobDetails,
        location: { ...DefaultJobLocation, country: "USA" },
        id: "00000000-0000-0000-0000-000000000000",
        campaign: 'Generic'
      };
      this.job.companyId = this.company!.id;
      this.job.aboutUs = this.company!.aboutUs ?? ''; // CHECKOUT
      this.job.whyUs = this.company!.whyWorkHere ?? '';
      this.job.compensation = { type: '', min: 0, max: 0 };
    }

    // education level array
    this.eduLevels = [
      { id: 'None', name: 'None' },
      { id: 'HS', name: 'High School Diploma/GED' },
      { id: 'Some', name: 'Some College' },
      { id: 'AD', name: 'Associates Degree' },
      { id: 'BA', name: 'Bachelors Degree' },
      { id: 'MS', name: 'Masters Degree' },
      { id: 'PhD', name: 'Doctorate Degree' },
      { id: 'Cert', name: 'Trade Certification' },
    ];

    // rate type array
    this.rateTypes = [
      { id: 'Yearly', name: 'Yearly' },
      { id: 'Hourly', name: 'Hourly' },
      { id: 'Fixed', name: 'Fixed' },
    ];

    this.empTypes = [
      { id: 'FULL_TIME', name: 'FULL_TIME' },
      { id: 'PART_TIME', name: 'PART_TIME' },
      { id: 'CONTRACTOR', name: 'CONTRACTOR' },
      { id: 'TEMPORARY', name: 'TEMPORARY' },
      { id: 'INTERN', name: 'INTERN' },
      { id: 'VOLUNTEER', name: 'VOLUNTEER' },
      { id: 'PER_DIEM', name: 'PER_DIEM' },
      { id: 'OTHER', name: 'OTHER' }
    ]

    // function array
    this.jobFunctions = [
      { id: '1', name: 'Accounting and Finance' },
      { id: '2', name: 'Administrative and Clerical' },
      { id: '3', name: 'Arts, Print, Design and Web' },
      { id: '4', name: 'Communication and Publishing' },
      { id: '5', name: 'Community and Social Services' },
      { id: '6', name: 'Construction and Skilled Trades' },
      { id: '7', name: 'Consulting' },
      { id: '8', name: 'Customer/Client Service and Support' },
      { id: '9', name: 'Education and Training' },
      { id: '10', name: 'Engineering' },
      { id: '11', name: 'Food server, Bartender, Cooks' },
      { id: '12', name: 'Healthcare' },
      { id: '13', name: 'Human Resources' },
      { id: '14', name: 'Installation, Maintenance, Repair' },
      { id: '15', name: 'Legal' },
      { id: '16', name: 'Management' },
      { id: '17', name: 'Marketing, Media and PR' },
      { id: '18', name: 'Military and Protective Services' },
      { id: '19', name: 'Operations' },
      { id: '20', name: 'Other' },
      { id: '21', name: 'Production and Quality' },
      { id: '22', name: 'Program, Policy and Project Management' },
      { id: '23', name: 'Purchasing, Logistics and Procurement' },
      { id: '24', name: 'Reseach, Data, Statistics, Library' },
      { id: '25', name: 'Sales and Business Development' },
      { id: '26', name: 'Science, Lab and Clinical' },
      { id: '27', name: 'Technology' },
      { id: '28', name: 'Transportation, Fleet and Delivery' },
      { id: '29', name: 'Unskilled Hourly' },
    ];
    this.$store.commit('setIsLoading', false);
  },
});
