import { UUID, NullableString } from './Types';

export default interface JobLocation {
    companyId: UUID | null,
    rank: number | null,
    name: NullableString,
    streetAddress: NullableString,
    subAddress: NullableString,
    city: NullableString,
    county: NullableString,
    stateOrProvince: NullableString,
    postalCode: NullableString,
    region: NullableString,
    country: NullableString,
    hq: boolean
}

export const DefaultJobLocation: JobLocation = {
    companyId: '00000000-0000-0000-0000-000000000000',
    rank: 0,
    name: null,
    streetAddress: null,
    subAddress: null,
    city: null,
    county: null,
    stateOrProvince: null,
    postalCode: null,
    region: null,
    country: 'USA',
    hq: false
}
