










































































import api from '@/api';
import Vue from 'vue';
import QualQuestion from '@/models/QualQuestion';
import CompanyAbstract from '@/models/CompanyAbstract';
import { UUID } from '@/models/Types';
import UserSession from '@/models/UserSession';
// components
import AnswerConfigCard from '@/components/job/AnswerConfigCard.vue';
import QuestionCard from '@/components/job/QuestionCard.vue';
import BreadCrumb from '@/components/BreadCrumb.vue';

interface SessionVM extends UserSession {
  payload: CompanyAbstract;
}

interface Data {
  questions: QualQuestion[];
  company: CompanyAbstract | null;
}

export default Vue.extend({
  name: 'QualQuestionConfig',
  components: {
    AnswerConfigCard,
    QuestionCard,
    BreadCrumb,
  },
  data(): Data {
    return {
      questions: [],
      company: null,
    };
  },
  methods: {
    async save() {
      try {
        let jobId = this.$route.params.id;
        const rslt = await this.$http.post(
          `${api}/questions/config/${jobId}`,
          this.selected,
          { headers: { Accept: 'application/json' } }
        );
        this.$router.push({ path: `/jobs/${jobId}/manage` });
        // window.location.assign(`#/jobs/${jobId}/manage`);
      } catch (error) {
        // eslint-disable-next-line
        console.error(error);
      }
    },
  },
  computed: {
    questionBank(): QualQuestion[] {
      return this.questions.filter((q) => !q.isAdded); // || q.isRepeatable);
    },
    selected(): QualQuestion[] {
      return this.questions.filter((q) => q.isAdded);
    },
  },
  async mounted() {
    try {
      let jobId = this.$route.params.id;
      // get defined and possible questions for job
      const rslt = await this.$http.get(`${api}/questions/config/${jobId}`, {
        headers: { Accept: 'application/json' },
      });
      this.questions = rslt.data;
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    }

    // pull company id
    const pid: UUID = this.$store.getters.currentProfile;
    if (pid == null) {
      // pull user's associated profile and redirect to home dashboard
      this.$router.push({ path: `/boards/${this.$store.getters.homeProfile}` });
      return;
    }

    // get company
    try {
      const rslt = await this.$http.get(`${api}/profiles/${pid}`);
      const rspn: SessionVM = rslt.data;
      this.company = rspn.payload;

      // const session: UserSession = {
      //                 token: rspn.token,
      //                 primaryOrg: rspn.primaryOrg,
      //                 currentOrg: rspn.currentOrg,
      //                 currentPrivileges: rspn.currentPrivileges };

      // this.$store.commit('setUserSession', session);
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    }
  },
});
